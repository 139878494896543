import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGE_BASE_URL, DEFAULT_IMAGE } from '../../utils/const';

export default function ProductCard({ product }) {
  const { name, description, sku, createdAt, _id, images } = product;
  console.log(product);
  const BASE_URL =
    !!images && images.length > 0
      ? `${IMAGE_BASE_URL}/w_350,h_200,c_fill/${images[0].url}`
      : DEFAULT_IMAGE;
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
      <div className="card text-center">
        <Link to={`/product/${_id}`}>
          <img className="card-img-top" src={BASE_URL} alt={name} />
        </Link>
        <Link to={`/product/${_id}`}>
          <div className="card-body">
            <h5 className="card-title">
              {name} {<small className="text-muted samll">{sku}</small>}
            </h5>
            <p className="card-text">
              {description && description.substring(0, 50)}
            </p>
            <p className="card-text">
              <small className="text-muted">
                {new Date(createdAt).toDateString()}
              </small>
            </p>
          </div>
        </Link>
        <div className="card-footer" dir="ltr">
          <Link className="card-link text-muted" to={`add-product/${_id}`}>
            <small>ערוך</small>
          </Link>
        </div>
      </div>
    </div>
  );
}
