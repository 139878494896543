import StyledComponent from 'styled-components';
export const WidgetWrapper = StyledComponent.div`
    background: #fff;
    border: 1px solid #e0e0e0;
    padding: 12px 20px 20px;
    border-radius: 6px;
    font-size: 0.85rem;
    text-align: ${props => props.align};
    flex-basis: ${props => props.basis ? props.basis : 'unset'}
    
    &{
    width: ${props => props.contentWidth ? props.contentWidth : (props.basis ? props.basis : 'unset')}
    }
`;