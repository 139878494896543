import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  getCategoryById,
  clearCurrentCategory,
  createCategory,
} from './category-duck/categoryActions';

import TextFieldGroup from '../../components/common/TextFieldGroup';
import TextAreaFieldGroup from '../../components/common/TextAreaFieldGroup';

import { IMAGE_BASE_URL } from '../../utils/const';

import './css/category.css';

class CreateCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      name: '',
      description: '',
      forSupplier: false,
      image: null,

      id: null,
      errors: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getCategoryById(this.props.match.params.id);
    } else {
      this.props.clearCurrentCategory();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.category) {
      const category = nextProps.category.category;
      const loading = nextProps.category.loading;
      if (category)
        this.setState({
          loading,
          name: category.name,
          description: category.description ? category.description : '',
          forSupplier: category.forSupplier,
          image: category.image,
          id: category._id,
        });

      if (loading)
        this.setState({
          loading,
        });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    console.log(this.state.image);
    const categoryData = {
      name: this.state.name,
      description: this.state.description,
      forSupplier: this.state.forSupplier,
      image: this.state.image,
    };

    if (this.state.id) categoryData.id = this.state.id;

    this.props.createCategory(categoryData, this.props.history, true);
  }

  onChange(e) {
    const target = e.target;

    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'image') {
      this.setState({ image: target.files });
      return;
    }

    this.setState({ [name]: value });
  }

  showWidget = () => {
    this.widget.open();
  };

  widget = window.cloudinary.createUploadWidget(
    {
      cloudName: 'karam-dev',
      uploadPreset: 'vazpo6tv',
      sources: ['local', 'url'],
      tags: ['xmas'],
    },
    (error, result) => {
      console.log('error', error);
      console.log('result', result);
      if (!error) {
        if (result && result.event === 'success') {
          const data = result.info;
          const image = {
            name: data.original_filename,
            url: data.path,
            size: data.bytes,
            thumbnail: data.thumbnail_url,
            hash: data.signature,
          };
          this.setState({ image });
        }
      }
    }
  );

  render() {
    const {
      errors,
      name,
      description,
      forSupplier,
      loading,
      image,
    } = this.state;

    return (
      <div className="create-category">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">הוספת מחלקה חדשה</h1>
              <p className="lead text-center">טופס הקמת מחלקה חדשה במערכת</p>
              <small className="d-block pb-3">שדות חובה = *</small>
              {loading ? (
                'Loading...'
              ) : (
                <form onSubmit={this.onSubmit} encType="multipart/form-data">
                  <TextFieldGroup
                    placeholder="שם המחלקה *"
                    name="name"
                    value={name}
                    onChange={this.onChange}
                    error={errors.name}
                  />

                  <TextAreaFieldGroup
                    placeholder="אפיון המחלקה"
                    name="description"
                    value={description}
                    onChange={this.onChange}
                    error={errors.description}
                  />

                  <span
                    className="btn btn-warning mb-3 mt-3"
                    onClick={this.showWidget}
                  >
                    Upload Image
                  </span>
                  {image ? (
                    <img
                      src={`${IMAGE_BASE_URL}/w_350,h_200,c_fill/${image.url}`}
                      alt={image.name}
                    />
                  ) : (
                    ''
                  )}
                  <div className="checkbox mt-3">
                    <label>
                      <input
                        type="checkbox"
                        value={forSupplier}
                        checked={forSupplier ? 1 : 0}
                        onChange={this.onChange}
                        name="forSupplier"
                      />
                      <span className="cr">
                        <i className="cr-icon fa fa-check" />
                      </span>
                      <span className="mr-2">רק לספקים</span>
                    </label>
                  </div>

                  <input
                    type="submit"
                    value="בצע"
                    className="btn btn-info btn-block mt-4"
                  />
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateCategory.propTypes = {
  getCategoryById: PropTypes.func.isRequired,
  createCategory: PropTypes.func.isRequired,
  clearCurrentCategory: PropTypes.func,

  category: PropTypes.object,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  category: state.category,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getCategoryById,
  clearCurrentCategory,
  createCategory,
})(withRouter(CreateCategory));
