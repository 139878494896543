import axios from 'axios';
import { setAlert } from '../../alert/alert-duck/alertActions';

import {
  GET_TODO,
  GET_TODOS,
  CLEAR_CURRENT_TODO,
  TODO_LOADING,
  GET_ERRORS,
} from '../../../app-duck/actions/types';

// Get list of Todo
export const getAllTodo = () => (dispatch) => {
  dispatch(setTodoLoading());
  axios
    .get(`/api/todo/all`)
    .then((res) => {
      console.log('response', res);
      return dispatch({
        type: GET_TODOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(('errors', err));
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get Todo by id
export const getTodoById = (id) => (dispatch) => {
  dispatch(setTodoLoading());
  axios
    .get(`/api/todo/${id}`)
    .then((res) =>
      dispatch({
        type: TODO_LOADING,
        payload: false,
      })
    )
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get Todo by id
export const getTodoForMe = (id) => (dispatch) => {
  dispatch(setTodoLoading());
  axios
    .get(`/api/todo/forme`)
    .then((res) =>
      dispatch({
        type: GET_TODOS,
        payload: res.data,
      })
    )
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Create Todo
export const createTodo = (todoData, history) => (dispatch) => {
  axios
    .post(`/api/todo`, todoData)
    .then((res) => {
      console.log('//TODO://add this todo to the old list', res);
      dispatch(setAlert('משימה חדשה!!', 'info'));

      window.location.reload();
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Delete todo by id
export const deleteTodoById = (id) => (dispatch) => {
  axios
    .delete(`/api/todo/${id}`)
    .then((res) => {
      dispatch(setAlert('המשימה נמחקה בהצלחה', 'info'));
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const clearCurrentTodo = () => {
  return {
    type: CLEAR_CURRENT_TODO,
  };
};

export const setTodoLoading = () => {
  return {
    type: TODO_LOADING,
  };
};
