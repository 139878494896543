import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInHomeProducts } from '../products/products-duck/productAction';
import Spinner from '../../components/common/Spinner';
import Card from '../../components/common/Card';
import { IMAGE_BASE_URL, DEFAULT_IMAGE } from '../../utils/const';

export class HomeProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }
  componentDidMount() {
    if (
      this.props.product &&
      this.props.product.home &&
      this.props.product.home.length > 0
    ) {
      this.setState({ products: this.props.product.home });
    } else this.props.getInHomeProducts();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.product) {
      this.setState({ products: nextProps.product.home });
    }
  }

  getProductImageUrl(product) {
    return !!product.images && product.images.length > 0
      ? `${IMAGE_BASE_URL}/w_350,h_200,c_fill/${product.images[0].url}`
      : DEFAULT_IMAGE;
  }

  render() {
    const { products } = this.state;

    return (
      <div className="container">
        <h1 className="display-5 text-center">מוצרים נמצאים במסך הראשי</h1>
        <div className="row mt-5">
          {products && products.length > 0 ? (
            products.map((product) => (
              <Card
                key={product._id}
                link={`add-product/${product._id}`}
                editLink={`add-product/${product._id}`}
                name={product.name}
                imageUrl={this.getProductImageUrl(product)}
                description={product.description}
              />
            ))
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    );
  }
}

HomeProductView.propTypes = {
  getInHomeProducts: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  product: state.product,
});

const mapDispatchToProps = { getInHomeProducts };

export default connect(mapStateToProps, mapDispatchToProps)(HomeProductView);
