import axios from 'axios';

import {
  GET_CUSTOMER,
  GET_CUSTOMERS,
  CUSTOMER_LOADING,
  CLEAR_CURRENT_CUSTOMER,
  GET_ERRORS,
} from '../../../app-duck/actions/types';

// Get customer by id
export const getCustomerById = (id) => (dispatch) => {
  dispatch(setCustomerLoading());
  axios
    .get(`/api/customers/${id}`)
    .then((res) =>
      dispatch({
        type: GET_CUSTOMER,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_CUSTOMER,
        payload: {},
      })
    );
};

// Get all customers by handle
export const getCustomers = () => (dispatch) => {
  dispatch(setCustomerLoading());
  axios
    .get(`/api/customers/all`)
    .then((res) => {
      return dispatch({
        type: GET_CUSTOMERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: GET_CUSTOMERS,
        payload: null,
      });
    });
};

// Create Customer
export const createCustomer = (customerData, history) => (dispatch) => {
  axios
    .post('/api/customers', customerData)
    .then((res) => history.push(`/customers`))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Customer loading
export const setCustomerLoading = () => {
  return {
    type: CUSTOMER_LOADING,
  };
};

// Clear customer
export const clearCurrentCustomer = () => {
  return {
    type: CLEAR_CURRENT_CUSTOMER,
  };
};
