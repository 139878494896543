import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../components/common/Spinner';
import { getAllTodo, deleteTodoById, createTodo } from './todo_duck/todoAction';
import { getAllSuppliers } from '../suppliers/suppliers-duck/supplierAction';
import AddNewTodo from './AddNewTodo';
// React Table
import ReactTable from 'react-table-6';

// React table style ".css"
import 'react-table/react-table.css';

class TODO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todoList: [],
      title: '',
      dueDate: new Date()
        .toLocaleDateString('uk')
        .split('.')
        .reverse()
        .join('-'),
      forUser: null,
      suppliers: [],

      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    console.log([e.target.name], e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();

    let _todo = {};
    _todo.forUser = this.state.forUser;
    _todo.title = this.state.title;
    _todo.dueDate = this.state.dueDate;

    console.log('submit', _todo);

    this.props.createTodo(_todo, this.props.history);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.todo) {
      this.setState({ todos: nextProps.todo.todos });
    }
    if (nextProps.supplier) {
      this.setState({ suppliers: nextProps.supplier.suppliers });
    }
  }
  componentDidMount() {
    this.props.getAllTodo();
    this.props.getAllSuppliers();
  }

  render() {
    const { loading } = this.props.todo;
    const { errors, title, todos, forUser, suppliers, dueDate } = this.state;

    let content;

    const columns = [
      {
        Header: 'מצב',
        accessor: 'isDone',
        maxWidth: 90,
        Cell: (row) => (
          <div className={row.row.isDone ? 'bg-success' : 'bg-danger'}>
            {row.row.isDone ? 'Done' : 'Not Done'}
          </div>
        ),
      },
      {
        Header: 'משימה',
        accessor: 'title',
        minWidth: 150,
      },
      {
        Header: 'תאריך יעד',
        accessor: 'dueDate',
        Cell: (row) => {
          return (
            <div>{new Date(row.row.dueDate).toLocaleDateString('uk')}</div>
          );
        },
      },
      {
        Header: 'עבור',
        accessor: 'forUser.name',
      },
      {
        Header: 'הושלם על ידי',
        accessor: 'doneBy.name',
      },
      {
        Header: 'מחק',
        id: '_id',
        accessor: '_id',
        maxWidth: 90,
        Cell: (row) => {
          return (
            <div>
              <button
                className="btn btn-danger"
                onClick={() => {
                  if (window.confirm('ברצונך למחוק הספק הזה?')) {
                    this.props.deleteTodoById(row.row._id);
                    window.location.reload(false);
                  }
                }}
              >
                מחק
              </button>
            </div>
          );
        },
      },
    ];

    if (todos === null || loading) {
      content = <Spinner />;
    } else if (todos && todos.length > 0) {
      content = (
        <ReactTable
          loading={loading}
          data={todos}
          columns={columns}
          pageSize={10}
        />
      );
    } else {
      content = <h4>אין משימות..</h4>;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 m-auto">
            <h1 className="display-4 text-center">משימות</h1>

            <AddNewTodo
              onChange={this.onChange}
              onSubmit={this.onSubmit}
              title={title}
              error={errors.title}
              forUser={forUser}
              dueDate={dueDate}
              users={
                suppliers
                  ? suppliers.map((su) => {
                      return { label: su.name, value: su._id };
                    })
                  : []
              }
            />
            <div className="mt-4">{content}</div>
          </div>
        </div>
      </div>
    );
  }
}

TODO.propTypes = {
  getAllTodo: PropTypes.func.isRequired,
  getAllSuppliers: PropTypes.func.isRequired,
  createTodo: PropTypes.func,
  deleteTodoById: PropTypes.func,
  auth: PropTypes.object.isRequired,
  supplier: PropTypes.object.isRequired,
  todo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  todo: state.todo,
  supplier: state.supplier,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAllTodo,
  createTodo,
  deleteTodoById,
  getAllSuppliers,
})(TODO);
