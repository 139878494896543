import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getAllSuppliers,
  deleteSupplierById,
} from './suppliers-duck/supplierAction';
import InputGroup from '../../components/common/InputGroup';
import Spinner from '../../components/common/Spinner';
import { Link } from 'react-router-dom';

// React Table
import ReactTable from 'react-table-6';

// React table style ".css"
import 'react-table/react-table.css';

class SuppliersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      suppliers: [],
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
  }

  fillTableWithSearchFilter(search) {
    var updatedList = this.props.supplier.suppliers;
    updatedList = updatedList.filter(function (item) {
      return (
        item.name.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.email.toLowerCase().search(search.toLowerCase()) !== -1 ||
        (item.phoneNumber
          ? item.phoneNumber.toLowerCase().search(search.toLowerCase()) !== -1
          : false)
      );
    });
    this.setState({ suppliers: updatedList });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'search') {
      const search = e.target.value;
      if (search === '') {
        this.setState({ suppliers: this.props.supplier.suppliers });
        return;
      }
      this.fillTableWithSearchFilter(search);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.supplier) {
      this.setState({ suppliers: nextProps.supplier.suppliers });
    }
  }
  componentDidMount() {
    this.props.getAllSuppliers();
  }

  render() {
    const { loading } = this.props.supplier;
    const { suppliers, errors } = this.state;

    let content;

    /*function folderFormatter(folder, ff) {
      return `${folder.prefix} ${folder.num}`;
    }*/

    const columns = [
      {
        Header: 'שם ספק',
        accessor: 'name', // String-based value accessors!
      },
      {
        Header: 'דואר',
        accessor: 'email',
        minWidth: 150,
      },
      {
        Header: 'מספר טלפון',
        accessor: 'phoneNumber',
      },
      {
        Header: 'מחק',
        id: '_id',
        accessor: '_id',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              <button
                className="btn btn-danger"
                onClick={() => {
                  if (window.confirm('ברצונך למחוק הספק הזה?')) {
                    this.props.deleteSupplierById(row.row._id);
                    window.location.reload(false);
                  }
                }}
              >
                מחק
              </button>
            </div>
          );
        },
      },
    ];

    if (suppliers === null || loading) {
      content = <Spinner />;
    } else if (suppliers.length > 0) {
      content = (
        <div>
          <ReactTable
            loading={loading}
            data={suppliers}
            columns={columns}
            pageSize={10}
          />
        </div>
      );
    } else {
      content = <h4>לא נמצא ספקים..</h4>;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 m-auto">
            <h1 className="display-4 text-center">רשימת ספקים</h1>

            <div className="container">
              <div className="row">
                <div className="col-md-8 m-auto">
                  <form onSubmit={this.onSubmit}>
                    <InputGroup
                      placeholder="מחפשים ספק? הקלידו כאן ונמצא לכם"
                      name="search"
                      value={this.state.search}
                      onChange={this.onChange}
                      error={errors.search}
                      icon="fas fa-search"
                    />
                  </form>
                </div>
              </div>
            </div>

            {content}
          </div>
        </div>
      </div>
    );
  }
}

SuppliersTable.propTypes = {
  getAllSuppliers: PropTypes.func.isRequired,
  deleteSupplierById: PropTypes.func,
  auth: PropTypes.object.isRequired,
  supplier: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  supplier: state.supplier,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAllSuppliers,
  deleteSupplierById,
})(SuppliersTable);
