import React from 'react';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import { IMAGE_BASE_URL } from '../../utils/const';

export default function PriceWithImage({
  id,
  name,
  value,
  placeholder,
  error,
  onChange,
  imgae,
  onImage,
}) {
  let img = imgae;

  window['widgets'] = window['widgets'] || {};
  window['widgets'][id] =
    window['widgets'][id] ||
    window.cloudinary.createUploadWidget(
      {
        cloudName: 'karam-dev',
        uploadPreset: 'vazpo6tv',
        sources: ['local', 'url'],
        tags: ['xmas'],
      },
      (error, result) => {
        if (!error) {
          if (result && result.event === 'success') {
            const data = result.info;
            const image = {
              name: data.original_filename,
              url: data.path,
              size: data.bytes,
              thumbnail: data.thumbnail_url,
              hash: data.signature,
            };
            img = image;
            onImage(name.replace('price', 'image'), img);
          }
        }
      }
    );

  const imageView = () => {
    return (
      <div className="col-6">
        <img
          src={`${IMAGE_BASE_URL}/w_350,h_200,c_fill/${img.url}`}
          alt={img.name}
        />
      </div>
    );
  };
  const showWidget = () => {
    window['widgets'][id].open();
  };

  return (
    <div className="row">
      <div className="col-9">
        <TextFieldGroup
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          type="number"
          step="0.1"
          error={error}
        />
      </div>

      <div className="col">
        <span
          className="btn btn-sm btn-warning mb-3 mt-3 "
          onClick={showWidget}
        >
          Upload Image
        </span>
      </div>

      {!!img ? imageView() : <></>}
    </div>
  );
}
