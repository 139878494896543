import React from 'react';
import PropTypes from 'prop-types';

const CheckBoxField = ({
  name,
  placeholder,
  value,
  label,
  error,
  onChange,
}) => {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        placeholder={placeholder}
        name={name}
        checked={value}
        onChange={onChange}
        id={name + '_checkbox'}
      />

      <label
        className="form-check-label check-label"
        htmlFor={name + '_checkbox'}
      >
        {label}
      </label>

      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

CheckBoxField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CheckBoxField.defaultProps = {
  type: 'text',
};

export default CheckBoxField;
