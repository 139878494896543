import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import { createCustomer } from './customers-duck/customerActions';

class CreateCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      idNumber: '',
      address: '',
      phoneNumber: '',
      frequency: '',
      email: '',
      address: '',

      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const customerData = {
      name: this.state.name,
      idNumber: this.state.idNumber,
      phoneNumber: this.state.phoneNumber,
      frequency: this.state.frequency,
      email: this.state.email,
      address: this.state.address,
    };

    this.props.createCustomer(customerData, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="create-profile">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">הוספת לקוח חדש</h1>
              <p className="lead text-center">טופס הקמת לקוח חדש למערכת</p>
              <small className="d-block pb-3">* = שדות חובה</small>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="* שם"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                />

                <TextFieldGroup
                  placeholder="* כתובת"
                  name="address"
                  value={this.state.address}
                  onChange={this.onChange}
                  error={errors.address}
                />

                <TextFieldGroup
                  placeholder="/חפ מס' ת''ז"
                  name="idNumber"
                  value={this.state.idNumber}
                  onChange={this.onChange}
                  error={errors.idNumber}
                  type="Numbers"
                  info="מספר תעודת זהוי או עוסק מורשה"
                />
                <TextFieldGroup
                  placeholder="* מס' נייד"
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.onChange}
                  error={errors.phoneNumber}
                  type="number"
                  info="מספר טלפון נייד כולל קידומת"
                />
                <TextFieldGroup
                  placeholder="תדר ביקור"
                  name="frequency"
                  value={this.state.frequency}
                  onChange={this.onChange}
                  error={errors.frequency}
                  type="number"
                  info="תדר ביקור"
                />
                <TextFieldGroup
                  placeholder="דואר אליקטרוני"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                  type="email"
                  info="למשל Example@domain.com"
                />

                <input
                  type="submit"
                  value="בצע"
                  className="btn btn-info btn-block mt-4"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateCustomer.propTypes = {
  customer: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  errors: state.errors,
});

export default connect(mapStateToProps, { createCustomer })(
  withRouter(CreateCustomer)
);
