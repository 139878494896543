import {
  GET_SUPPLIER,
  GET_SUPPLIERS,
  CLEAR_CURRENT_SUPPLIER,
  SUPPLIER_LOADING,
} from '../../../app-duck/actions/types';

const initialState = {
  suppliers: null,
  supplier: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUPPLIER:
      return { ...state, loading: false, supplier: action.payload };
    case GET_SUPPLIERS:
      return { ...state, loading: false, suppliers: action.payload };
    case SUPPLIER_LOADING:
      return { ...state, loading: true };
    case CLEAR_CURRENT_SUPPLIER:
      return { ...state, supplier: null };
    default:
      return state;
  }
}
