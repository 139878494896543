import axios from 'axios';
import { setAlert } from '../../alert/alert-duck/alertActions';

import {
  GET_PRODUCT,
  GET_PRODUCTS,
  CLEAR_CURRENT_PRODUCT,
  PRODUCTS_LOADING,
  GET_ERRORS,
  PRODUCTS_IN_HOME,
} from '../../../app-duck/actions/types';

// Get Product by id
export const getProductById = (id) => (dispatch) => {
  dispatch(setProductLoading());
  axios
    .get(`/api/products/${id}`)
    .then((res) =>
      dispatch({
        type: GET_PRODUCT,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get all products
export const getAllProducts = () => (dispatch) => {
  dispatch(setProductLoading());
  axios
    .get(`/api/products/all`)
    .then((res) => {
      return dispatch({
        type: GET_PRODUCTS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get InHome Products
export const getInHomeProducts = () => (dispatch) => {
  dispatch(setProductLoading());
  axios
    .get('/api/products/home')
    .then((res) =>
      dispatch({
        type: PRODUCTS_IN_HOME,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// Create Product
export const createProduct = (productData, history, isNew) => (dispatch) => {
  axios
    .post('/api/products', productData)
    .then((res) => {
      // notifiaction

      isNew
        ? dispatch(setAlert('המוצר ' + res.data.name + ', נוסף בהצלחה', 'info'))
        : dispatch(setAlert('המוצר ' + res.data.name + ', עודכן בהצלחה', ''));
      //go to the previsos page

      history.push(`/products`);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Delete Product By ID
export const deleteProduct = (id, history) => (dispatch) => {
  axios
    .delete(`/api/products/${id}`)
    .then((res) => {
      history.push(`/products`);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Product Loading
export const setProductLoading = () => {
  return {
    type: PRODUCTS_LOADING,
  };
};

// Clear current product data
export const clearCurrentProduct = () => {
  return {
    type: CLEAR_CURRENT_PRODUCT,
  };
};
