import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getAllCategories } from './category-duck/categoryActions';

import CategoryCard from './CategoryCard';

// React Table
import ReactTable from 'react-table';

// React table style ".css"
//import 'react-table/react-table.css';

//import './css/category.css';

class CategoriesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      categories: [],
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.category) {
      this.setState({ categories: nextProps.category.categories });
    }
  }

  componentDidMount() {
    this.props.getAllCategories();
  }

  fillTableWithSearchFilter(search) {
    let updatedList = this.props.category.categories;
    updatedList = updatedList.filter(function (item) {
      return (
        item.name.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.description.toString().search(search.toLowerCase()) !== -1 ||
        new Date(item.createdAt)
          .toLocaleDateString()
          .toString()
          .toLowerCase()
          .search(search.toLowerCase()) !== -1
      );
    });
    this.setState({ categories: updatedList });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'search') {
      const search = e.target.value;
      if (search === '') {
        this.setState({ categories: this.props.document.categories });
        return;
      }
      this.fillTableWithSearchFilter(search);
    }
  }

  render() {
    return (
      <div className="row mt-5">
        {this.state.categories &&
          this.state.categories.map((cat, i) => (
            <CategoryCard key={i} category={cat} />
          ))}
      </div>
    );
  }
}

CategoriesTable.propTypes = {
  getAllCategories: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  category: state.category,
  errors: state.errors,
});

export default connect(mapStateToProps, { getAllCategories })(CategoriesTable);
