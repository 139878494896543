import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getCustomerById } from './customers-duck/customerActions';
import { getCustomerDocuments } from '../documents/document-duck/documentAction';
import { WidgetWrapper } from '../../components/ui/WidgetWrapper';

import DocumentsTable from '../documents/DocumentsTable';
import CustomerView from './CustomerView';
import { formatDate } from '../../utils/util';
import './css/customer-profile.css';

class CustomerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      match: { params },
      getCustomerById,
    } = this.props;

    getCustomerById(params.id);
  }

  getData = () => {
    const {
      match: { params },
      getCustomerDocuments,
    } = this.props;
    getCustomerDocuments(params.id);
  };

  render() {
    const {
      customer: { customer },
    } = this.props;
    return (
      <div className={'customer-profile-wrapper'}>
        {/*Top Section*/}
        {customer ? (
          <div className="col-md-12 edit-profile">
            <CustomerView customer={customer} />
            <Link to={`/customer/${customer._id}`}>
              <span className="text-center">
                <i className="far fa-edit" />
                <br />
                ערוך לקוח
              </span>
            </Link>
          </div>
        ) : (
          ''
        )}

        <hr />
        {/* profile */}
        <div className="row">
          <div className="col-sm-3">
            {' '}
            <WidgetWrapper className={'user-info'}>
              <ul>
                <li>{customer && customer.name}</li>

                <li>
                  <b>ביקור אחרון: </b>
                  {customer && formatDate(customer.lastVisit)}
                </li>
                <li>
                  <b>ביקור בא: </b>
                  {customer && formatDate(customer.nextVisit)}
                </li>

                {customer && customer.idNumber ? (
                  <li>{customer.idNumber}</li>
                ) : (
                  ''
                )}

                {customer && customer.email ? <li>{customer.email}</li> : ''}

                <li>{customer && customer.phoneNumber}</li>
                <li>
                  <span dir="ltr">{customer && customer.visitsCount} </span>
                </li>
              </ul>
            </WidgetWrapper>
          </div>
          <div className="col-sm-9 transactions">
            {customer &&
              customer.visits.reverse().map((visit) => (
                <div
                  key={visit._id}
                  className="row "
                  style={{ margin: '10px 0' }}
                >
                  <div className="col-md-12">
                    <i className="far fa-map" /> {formatDate(visit.date)}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="main-wrapper" />
      </div>
    );
  }
}

CustomerProfile.propTypes = {
  getCustomerById: PropTypes.func,
  getCustomerDocuments: PropTypes.func,
  auth: PropTypes.object,
  customer: PropTypes.object,
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  documents: state.document,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getCustomerById,
  getCustomerDocuments,
})(CustomerProfile);
