import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import ProductCard from '../products/ProductCard';
import {
  getCategoryById,
  clearCurrentCategory,
  getCategoryProductsByCategoryId,
} from './category-duck/categoryActions';
import { IMAGE_BASE_URL } from '../../utils/const';

import './css/category.css';

class CategoryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: null,
      loading: true,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getCategoryById(this.props.match.params.id);
      this.props.getCategoryProductsByCategoryId(this.props.match.params.id);
    } else {
      //404
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.category) {
      const category = nextProps.category.category;
      const products = nextProps.category.products;
      const loading = nextProps.category.loading;
      if (category) this.setState({ category, loading });
      if (products) this.setState({ products, loading });
      if (loading) this.setState({ loading });
    }
  }

  categoryView() {
    const { category } = this.state;
    return (
      <div className="category mt-4">
        <img
          src={`${IMAGE_BASE_URL}/w_1000,h_300,c_fill/${category.image.url}`}
          alt={category.image.name}
        />
        <h1 className="title mt-2">{category.name}</h1>
        <p>{category.description}</p>
      </div>
    );
  }

  productsView() {
    const { products } = this.state;
    return (
      <div className="product mt-4">
        <div className="row mt-5">
          {products &&
            products.map((product, i) => {
              return <ProductCard key={i} product={product} />;
            })}
        </div>
      </div>
    );
  }

  render() {
    const { loading, products } = this.state;
    return (
      <div className="category-view">
        {loading ? 'loading....' : this.categoryView()}
        {loading && !!products ? 'loading....' : this.productsView()}
      </div>
    );
  }
}

CategoryView.propTypes = {
  getCategoryById: PropTypes.func.isRequired,
  getCategoryProductsByCategoryId: PropTypes.func.isRequired,
  clearCurrentCategory: PropTypes.func,

  category: PropTypes.object,
};

const mapStateToProps = (state) => ({
  category: state.category,
});

export default connect(mapStateToProps, {
  getCategoryById,
  clearCurrentCategory,
  getCategoryProductsByCategoryId,
})(CategoryView);
