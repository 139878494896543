import axios from 'axios';
import { setAlert } from '../../alert/alert-duck/alertActions';

import {
  GET_SUPPLIER,
  GET_SUPPLIERS,
  CLEAR_CURRENT_SUPPLIER,
  SUPPLIER_LOADING,
  GET_ERRORS,
} from '../../../app-duck/actions/types';

// Get All Suppliers
export const getAllSuppliers = () => (dispatch) => {
  dispatch(setSupplierLoading());
  axios
    .get(`/api/users/suppliers`)
    .then((res) =>
      dispatch({
        type: GET_SUPPLIERS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get Supplier by id
export const getSupplierById = (id) => (dispatch) => {
  dispatch(setSupplierLoading());
  axios
    .get(`/api/users/supplier/${id}`)
    .then((res) =>
      dispatch({
        type: GET_SUPPLIER,
        payload: res.data,
      })
    )
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Create Supplier
export const createSupplier = (supplierData, history) => (dispatch) => {
  axios
    .post(`/api/users/reg_supplier`, supplierData)
    .then((res) => {
      dispatch(setAlert('ספק חדש!!', 'info'));

      history.push('/suppliers');
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Delete supplier by id
export const deleteSupplierById = (id) => (dispatch) => {
  axios
    .delete(`/api/users/delete_supplier/${id}`)
    .then((res) => {
      dispatch(setAlert('הספק נמחק בהצלחה', 'info'));
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const clearCurrentSupplier = () => {
  return {
    type: CLEAR_CURRENT_SUPPLIER,
  };
};

export const setSupplierLoading = () => {
  return {
    type: SUPPLIER_LOADING,
  };
};
