import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  createCustomer,
  getCustomerById,
} from './customers-duck/customerActions';
import { setAlert } from '../alert/alert-duck/alertActions';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import Spinner from '../../components/common/Spinner';

import isEmpty from '../../validation/is-empty';
import axios from 'axios';

class EditCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      idNumber: '',
      email: '',
      phoneNumber: '',
      frequency: '',
      address: '',
      isDeleting: false,

      errors: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    const {
      match: { params },
      getCustomerById,
    } = this.props;
    getCustomerById(params.id);
  }

  onSubmit(e) {
    e.preventDefault();

    const customerData = {
      id: this.state.id,
      name: this.state.name,
      idNumber: this.state.idNumber,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      frequency: this.state.frequency,
      address: this.state.address,
    };
    this.props.createCustomer(customerData, this.props.history, false);
  }

  handleDelete(e) {
    e.preventDefault();
    if (!!window.confirm('האם תרצה למחוק הלקוח הזה!')) {
      this.setState({ isDeleting: true });
      axios
        .delete(`/api/customers/${this.state.id}`)
        .then((res) => {
          this.props.setAlert('הלקוח נמחק בהצלחה', 'info');
          this.props.history.push('/customers');
        })
        .catch((err) => {
          this.setState({ isDeleting: false });
          this.props.setAlert('אין אפשרות למחוק הלקוח הזה', 'danger');
        });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.customer.customer) {
      const customer = nextProps.customer.customer;
      // if customer field doesnt exist, make empty string
      customer.name = !isEmpty(customer.name) ? customer.name : '';

      customer.idNumber = !isEmpty(customer.idNumber)
        ? customer.idNumber.toString()
        : '';
      customer.email = !isEmpty(customer.email) ? customer.email : '';
      customer.phoneNumber = !isEmpty(customer.phoneNumber)
        ? customer.phoneNumber + ''
        : '';
      customer.address = !isEmpty(customer.address) ? customer.address : '';
      customer.id = !isEmpty(customer._id) ? customer._id : '';
      customer.frequency = !isEmpty(customer.frequency)
        ? customer.frequency.toString()
        : 0;

      this.setState({
        id: customer.id,
        name: customer.name,
        idNumber: customer.idNumber,
        email: customer.email,
        phoneNumber: customer.phoneNumber,
        address: customer.address,
        frequency: customer.frequency,
      });
    }
  }

  render() {
    const { errors, isDeleting } = this.state;

    return (
      <div className="create-product">
        <div className="container">
          <div className="row">
            {isDeleting ? (
              <Spinner />
            ) : (
              <div className="col-md-8 m-auto">
                <h1 className="display-4 text-center">עדכון לקוח קיים</h1>
                <p className="lead text-center">טופס עדכון לקוח קיים במערכת</p>
                <small className="d-block pb-3">שדות חובה = *</small>
                <form onSubmit={this.onSubmit}>
                  <TextFieldGroup
                    placeholder="* שם"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                    error={errors.name}
                  />

                  <TextFieldGroup
                    placeholder="* כתובת"
                    name="address"
                    value={this.state.address}
                    onChange={this.onChange}
                    error={errors.address}
                  />

                  <TextFieldGroup
                    placeholder="מס' ת''ז"
                    name="idNumber"
                    value={this.state.idNumber}
                    onChange={this.onChange}
                    error={errors.idNumber}
                    type="string"
                    info="מספר תעודת זהוי או דרכון"
                  />
                  <TextFieldGroup
                    placeholder="* מס' נייד"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.onChange}
                    error={errors.phoneNumber}
                    type="numbers"
                    info="מספר טלפון נייד כולל קידומת"
                  />
                  <TextFieldGroup
                    placeholder="תדר ביקור"
                    name="frequency"
                    value={this.state.frequency}
                    onChange={this.onChange}
                    error={errors.frequency}
                    type="number"
                    info="תדר ביקור"
                  />
                  <TextFieldGroup
                    placeholder="דואר אליקטרוני"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    error={errors.email}
                    type="email"
                    info="למשל Example@domain.com"
                  />

                  <input
                    type="submit"
                    value="ערוך"
                    className="btn btn-info btn-block mt-4"
                  />
                </form>
                <button
                  className="btn btn-danger btn-block mt-4"
                  onClick={this.handleDelete}
                >
                  מחק
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

EditCustomer.propTypes = {
  createCustomer: PropTypes.func.isRequired,
  getCustomerById: PropTypes.func.isRequired,
  setAlert: PropTypes.func,
  customer: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  createCustomer,
  getCustomerById,
  setAlert,
})(withRouter(EditCustomer));
