import React from 'react';
import InputGroup from '../../components/common/InputGroup';
import SelectListGroup from '../../components/common/SelectListGroup';
import TextFieldGroup from '../../components/common/TextFieldGroup';

const AddNewTodo = ({
  error,
  title,
  onSubmit,
  onChange,
  users = [],
  forUser,
  dueDate,
}) => {
  users.unshift({ value: 0, label: 'לכל העובדים' });
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 m-auto">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-12">
                <InputGroup
                  placeholder="כתב משימה חדשה"
                  value={title}
                  error={error}
                  name="title"
                  onChange={onChange}
                  icon="fas fa-thumbtack"
                />
              </div>
              <div className="col-md-6">
                <SelectListGroup
                  name="forUser"
                  value={forUser}
                  onChange={onChange}
                  options={users}
                />
              </div>
              <div className="col-md-6">
                <TextFieldGroup
                  name="dueDate"
                  value={dueDate}
                  onChange={onChange}
                  type="date"
                />
              </div>
            </div>
            <input
              type="submit"
              value="הוספה"
              className="btn btn-success btn-lg btn-block"
            />
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddNewTodo;
