import {
  GET_CATEGORY,
  GET_CATEGORIES,
  GET_CATEGORY_PRODUCTS,
  CLEAR_CURRENT_CATEGORY,
  CATEGORY_LOADING,
  CATEGORY_NOTIFICATION,
  CLEAR_CATEGORY_NOTIFICATION,
} from '../../../app-duck/actions/types';

const initialState = {
  category: null,
  categories: null,
  products: null,
  loading: false,
  notification: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY: {
      return {
        ...state,
        loading: false,
        category: action.payload,
      };
    }
    case GET_CATEGORIES: {
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    }
    case GET_CATEGORY_PRODUCTS: {
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    }
    case CLEAR_CURRENT_CATEGORY: {
      return {
        ...state,
        category: null,
      };
    }
    case CATEGORY_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case CATEGORY_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload,
      };
    }
    case CLEAR_CATEGORY_NOTIFICATION: {
      return {
        ...state,
        notification: null,
      };
    }

    default:
      return state;
  }
}
