import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import MultiSelect from 'react-multi-select-component';

import {
  createProduct,
  getProductById,
  clearCurrentProduct,
  deleteProduct,
} from './products-duck/productAction';
import { getAllCategories } from '../categories/category-duck/categoryActions';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import TextAreaFieldGroup from '../../components/common/TextAreaFieldGroup';
import Spinner from '../../components/common/Spinner';
import { IMAGE_BASE_URL } from '../../utils/const';

import './css/products.css';
import PriceWithImage from './PriceWithImage';
import CheckBoxField from '../../components/common/CheckBoxField';

class CreateProduct extends Component {
  constructor(props) {
    super(props);
    this.widgetId = '_' + new Date().getTime();
    this.state = {
      name: '',
      description: '',
      sku: '',
      categories: [],
      isInHome: false,
      price70Gram: '',
      image70Gram: null,
      price200Gram: '',
      image200Gram: null,
      price1Kilo: '',
      image1Kilo: null,
      priceBox: '',
      imageBox: null,
      images: [],
      selectedCategories: [],

      id: null,
      isEditMode: false,
      loading: false,
      errors: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onImgae = this.onImgae.bind(this);
    this.onSelectCategory = this.onSelectCategory.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
  }

  componentDidMount() {
    if (this.props.category.categories) {
      this.setState({ categories: this.props.category.categories });
    } else {
      this.props.getAllCategories();
    }

    if (this.props.match.params.id) {
      this.setState({ isEditMode: true, loading: true });
      this.props.getProductById(this.props.match.params.id);
    } else {
      this.props.clearCurrentProduct();
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const productData = {
      name: this.state.name,
      description: this.state.description,
      sku: this.state.sku,
      isInHome: this.state.isInHome,
      price70Gram: this.state.price70Gram,
      image70Gram: this.state.image70Gram,
      price200Gram: this.state.price200Gram,
      image200Gram: this.state.image200Gram,
      price1Kilo: this.state.price1Kilo,
      image1Kilo: this.state.image1Kilo,
      priceBox: this.state.priceBox,
      imageBox: this.state.imageBox,
      images: this.state.images,
      categories: [],
    };
    productData.categories.push(
      this.state.selectedCategories.map((cat) => cat.value)
    );
    productData.categories = productData.categories.join(',');
    if (this.state.id !== null) {
      productData.id = this.state.id;
    }

    this.props.createProduct(productData, this.props.history, true);
  }

  onChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  onImgae(name, value) {
    this.setState({ [name]: value });
  }

  onSelectCategory(categories) {
    this.setState({ selectedCategories: categories });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.category) {
      this.setState({ categories: nextProps.category.categories });
    }

    if (nextProps.product && nextProps.product.product) {
      console.log(nextProps);
      const product = nextProps.product.product;
      const loading = nextProps.product.loading;
      const _prod = {
        id: product._id,
        name: product.name,
        isInHome: !!product.isInHome,
        description: product.description,
        sku: product.sku,
        price70Gram: product.price70Gram && product.price70Gram.amount + '',
        image70Gram: product.price70Gram && product.price70Gram.image,

        price200Gram: product.price200Gram && product.price200Gram.amount + '',
        image200Gram: product.price200Gram && product.price200Gram.image,

        price1Kilo: product.price1Kilo && product.price1Kilo.amount + '',
        image1Kilo: product.price1Kilo && product.price1Kilo.image,

        priceBox: product.priceBox && product.priceBox.amount + '',
        imageBox: product.priceBox && product.priceBox.image,
        isDeleted: product.isDeleted,
        images: product.images,
      };

      let selectedCategories = [];
      selectedCategories = product.categories.map((cat) => {
        return { label: cat.name, value: cat._id };
      });

      this.setState({
        ..._prod,
        loading,
        selectedCategories,
      });
      if (loading) this.setState({ loading });
    }
  }

  removeImage(index) {
    const { images } = this.state;
    images.splice(index, 1);
    this.setState({ images });
  }

  imagesView() {
    const { images } = this.state;
    return (
      <div className="row">
        {images.map((img, i) => (
          <div key={i} className="col-sm-6 col-md-4 mt-2">
            <span className="close" onClick={() => this.removeImage(i)}></span>
            <img
              src={`${IMAGE_BASE_URL}/w_350,h_200,c_fill/${img.url}`}
              alt={img.name}
            />
          </div>
        ))}
      </div>
    );
  }

  deleteProduct() {
    const result = window.confirm(`תרצה למחוק ${this.state.name} ?`);
    if (result) {
      this.props.deleteProduct(this.state.id, this.props.history);
    }
  }
  showWidget = () => {
    this.widget.open();
  };

  widget = window.cloudinary.createUploadWidget(
    {
      cloudName: 'karam-dev',
      uploadPreset: 'vazpo6tv',
      sources: ['local', 'url'],
      tags: ['xmas'],
    },
    (error, result) => {
      if (!error) {
        if (result && result.event === 'success') {
          const data = result.info;
          const { images } = this.state;
          const image = {
            name: data.original_filename,
            url: data.path,
            size: data.bytes,
            thumbnail: data.thumbnail_url,
            hash: data.signature,
          };
          images.push(image);
          this.setState({ images });
        }
      }
    }
  );

  render() {
    const { errors, loading, isEditMode } = this.state;
    const categoriesOption = [];
    this.state.categories &&
      this.state.categories.forEach((cat) => {
        categoriesOption.push({ label: cat.name, value: cat._id });
      });
    return !!errors.noproduct ? (
      <h1>{errors.noproduct}</h1>
    ) : loading ? (
      <Spinner></Spinner>
    ) : (
      <div className="create-product">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">הספת מוצר חדש</h1>
              <p className="lead text-center">טופס הקמת מוצר חדש במערכת</p>
              <small className="d-block pb-3">שדות חובה = *</small>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="שם המוצר *"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                />
                <MultiSelect
                  options={categoriesOption}
                  isLoading={
                    !(this.state.categories && this.state.categories.length > 0)
                  }
                  name="selectedCategories"
                  onChange={this.onSelectCategory}
                  value={this.state.selectedCategories}
                  labelledBy="מחלקה"
                  className="mb-3"
                />

                <TextAreaFieldGroup
                  placeholder="אפיון המוצר"
                  name="description"
                  value={this.state.description}
                  onChange={this.onChange}
                  error={errors.description}
                />

                <TextFieldGroup
                  placeholder="בארקוד *"
                  name="sku"
                  value={this.state.sku}
                  onChange={this.onChange}
                  error={errors.sku}
                />

                <CheckBoxField
                  name="isInHome"
                  label="מסך ראשי"
                  value={this.state.isInHome}
                  onChange={this.onChange}
                  error={errors.isInHome}
                />

                <h4>מחיר</h4>
                <hr />
                <label>70 גרם</label>
                <PriceWithImage
                  id={this.widgetId + 'a'}
                  name="price70Gram"
                  error={errors.price70Gram}
                  placeholder="70 גרם"
                  value={this.state.price70Gram}
                  onChange={this.onChange}
                  onImage={this.onImgae}
                  imgae={this.state.image70Gram}
                />
                <hr />
                <label>200 גרם</label>
                <PriceWithImage
                  id={this.widgetId + 'b'}
                  name="price200Gram"
                  error={errors.price200Gram}
                  placeholder="200 גרם"
                  value={this.state.price200Gram}
                  onChange={this.onChange}
                  onImage={this.onImgae}
                  imgae={this.state.image200Gram}
                />
                <hr />

                <label>קופסא</label>
                <PriceWithImage
                  id={this.widgetId + 'c'}
                  name="priceBox"
                  error={errors.priceBox}
                  value={this.state.priceBox}
                  onChange={this.onChange}
                  placeholder="קופסא"
                  onImage={this.onImgae}
                  imgae={this.state.imageBox}
                />
                <hr />

                <label>ק׳׳ג</label>
                <PriceWithImage
                  id={this.widgetId + 'd'}
                  name="price1Kilo"
                  error={errors.price1Kilo}
                  placeholder="ק׳׳ג"
                  value={this.state.price1Kilo}
                  onChange={this.onChange}
                  onImage={this.onImgae}
                  imgae={this.state.image1Kilo}
                />

                <hr />
                <h4>תמונת המוצר</h4>

                <span
                  className="btn btn-warning mb-3 mt-3"
                  onClick={this.showWidget}
                >
                  Upload Image
                </span>
                {this.imagesView()}

                <input
                  type="submit"
                  value="בצע"
                  className="btn btn-info btn-block mt-4"
                />
                {isEditMode ? (
                  <a
                    className="btn btn-danger mt-4 btn-delete"
                    onClick={this.deleteProduct}
                  >
                    מחק
                  </a>
                ) : (
                  <></>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateProduct.propTypes = {
  createProduct: PropTypes.func.isRequired,
  getAllCategories: PropTypes.func,
  deleteProduct: PropTypes.func,
  getProductById: PropTypes.func,
  clearCurrentProduct: PropTypes.func,

  product: PropTypes.object,
  category: PropTypes.object,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  product: state.product,
  category: state.category,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  createProduct,
  getAllCategories,
  getProductById,
  clearCurrentProduct,
  deleteProduct,
})(withRouter(CreateProduct));
