import axios from 'axios';

import {
  GET_CATEGORY,
  GET_CATEGORIES,
  GET_CATEGORY_PRODUCTS,
  CLEAR_CURRENT_CATEGORY,
  CATEGORY_LOADING,
  CATEGORY_NOTIFICATION,
  CLEAR_CATEGORY_NOTIFICATION,
  GET_ERRORS,
} from '../../../app-duck/actions/types';

//Get All Categories
export const getAllCategories = () => (dispatch) => {
  dispatch(setCategoryLoading());
  axios
    .get(`/api/categories/all`)
    .then((res) => {
      return dispatch({
        type: GET_CATEGORIES,
        payload: res.data,
      });
    })
    .catch(() =>
      dispatch({
        type: GET_CATEGORIES,
        payload: null,
      })
    );
};

//get category by id
export const getCategoryById = (id) => (dispatch) => {
  dispatch(setCategoryLoading());
  axios
    .get(`/api/categories/${id}`)
    .then((res) => {
      console.log(res);
      return dispatch({
        type: GET_CATEGORY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
//get category products by category id
export const getCategoryProductsByCategoryId = (id) => (dispatch) => {
  dispatch(setCategoryLoading());
  axios
    .get(`/api/products/category/${id}`)
    .then((res) => {
      console.log('getCategoryProductsByCategoryId', res);
      return dispatch({
        type: GET_CATEGORY_PRODUCTS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Create Category
export const createCategory = (categoryData, history) => (dispatch) => {
  axios
    .post(`/api/categories`, categoryData)
    .then((res) => {
      history.push('/categories');
      return dispatch({
        type: GET_CATEGORY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Category loading
export const setCategoryLoading = () => {
  return {
    type: CATEGORY_LOADING,
  };
};

// Clear Category
export const clearCurrentCategory = () => {
  return {
    type: CLEAR_CURRENT_CATEGORY,
  };
};
