import React from 'react';
import { Link } from 'react-router-dom';

export default ({ link, name, imageUrl, description, createdAt, editLink }) => (
  <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
    <div className="card text-center">
      <Link to={link}>
        <img className="card-img-top" src={imageUrl} alt={name} />
      </Link>
      <Link to={link}>
        <div className="card-body">
          <h5 className="card-title">{name} </h5>
          <p className="card-text">
            {description && description.substring(0, 50)}
          </p>
          {!!createdAt ? (
            <p className="card-text">
              <small className="text-muted">
                {new Date(createdAt).toDateString()}
              </small>
            </p>
          ) : (
            <></>
          )}
        </div>
      </Link>
      <div className="card-footer" dir="ltr">
        <Link className="card-link text-muted" to={editLink}>
          <small>ערוך</small>
        </Link>
      </div>
    </div>
  </div>
);
