import {
  GET_TODO,
  GET_TODOS,
  CLEAR_CURRENT_TODO,
  TODO_LOADING,
} from '../../../app-duck/actions/types';

const initialState = {
  todos: null,
  todo: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TODO:
      return { ...state, loading: false, todo: action.payload };
    case GET_TODOS:
      return { ...state, loading: false, todos: action.payload };
    case TODO_LOADING:
      return { ...state, loading: true };
    case CLEAR_CURRENT_TODO:
      return { ...state, todo: null };
    default:
      return state;
  }
}
