const zeroPad = (num, places) => {
  return num.toString().padStart(places, '0');
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('-');
};

export const PAYMENT_TYPE = Object.freeze({
  CREDITCARD: 'CREDITCARD',
  CHECK: 'CHECK',
  CASH: 'CASH',
  OTHER: 'OTHER',
});

export const DOCUMENT_TYPE = Object.freeze({
  RECEIPT: 'RECEIPT',
  INVOICE: 'INVOICE',
  BID: 'BID',
  CREDIT: 'CREDIT',
});

export const PRODUCT_TYPE = Object.freeze({
  UNIT: 'UNIT',
  LENGTH: 'LENGTH',
});

export const INVOICE_STATUS = Object.freeze({
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  FULL_PAID: 'FULL_PAID',
  NOT_PAID: 'NOT_PAID',
});

export default zeroPad;
