import React from 'react';

export default function ProductView({ product }) {
  const {
    name,
    description,
    sku,
    categories,
    price70Gram,
    price200Gram,
    price1Kilo,
    priceBox,
    images,
    createdAt,
    updatedAt,
  } = product;

  const priveView = (name, price) => {
    return price ? (
      <>
        <dt>{name}</dt>
        <dd>
          {price.amount} {price.image ? <img src={price.image} /> : <></>}
        </dd>
      </>
    ) : (
      <></>
    );
  };
  return !product ? (
    <div></div>
  ) : (
    <div className="product-view">
      <dl>
        <dt>Name</dt>
        <dd>{name}</dd>

        <dt>Images</dt>
        <dd>
          {images.map((img) => (
            <img src={img.thumbnail} alt={img.name} />
          ))}
        </dd>

        <dt>Description</dt>
        <dd>{description}</dd>

        <dt>sku</dt>
        <dd>{sku}</dd>

        <dt>categories</dt>
        <dd>{categories.map((cat) => cat.name + ', ')}</dd>

        {priveView('prive 70 gram', price70Gram)}
        {priveView('prive 200 gram', price200Gram)}
        {priveView('prive box', priceBox)}
        {priveView('prive 1 kilo', price1Kilo)}
      </dl>
    </div>
  );
}
